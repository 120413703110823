import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { VendorSelectorPopupComponent } from '@layout/components/vendor-selector-popup/vendor-selector-popup.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Role } from '@shared/enums';
import {
	BreadcrumbService,
	MetaDataService,
	NavigationService,
	UserContextService,
	UserRoleService,
} from '@shared/services';
import { TokenHelper } from '@shared/util/token-helper';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { IVendorModel } from '@shared/models/vendor';

@UntilDestroy()
@Component({
	selector: 'app-layout',
	templateUrl: './authorized-layout.component.html',
	styleUrls: ['./authorized-layout.component.scss'],
	providers: [DialogService],
})
export class AuthorizedLayoutComponent implements OnInit {
	public items: MenuItem[] = [];
	public crumbs$: Observable<MenuItem[]>;
	public userResult$: Observable<IUserDisplayInfo>;
	private selectedVendorId: number | null = null;
	private _hasAccessToVendorDD$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly badgeText: string = 'New';
	public isPrintersSidebarVisible: boolean = false;

	constructor(
		private oidcSecurityService: OidcSecurityService,
		private breadcrumb: BreadcrumbService,
		private metadata: MetaDataService,
		private navigationService: NavigationService,
		private dialogService: DialogService,
		private vendorService: UserContextService,
		private userRoleService: UserRoleService,
	) {
		this.crumbs$ = this.breadcrumb.crumbs$;
		this.userResult$ = combineLatest({
			vendor: this.vendorService.getVendor(),
			user: this.oidcSecurityService.checkAuth().pipe(filter(_ => _.userData != null)),
		}).pipe(
			map(result => {
				const hasAccess = this.hasAccessToManyVendors(result.user.userData);
				this._hasAccessToVendorDD$.next(hasAccess);
				return {
					userName: result.user.userData.preferred_username,
					role: TokenHelper.getRole(result.user.userData, result.vendor.id),
					avatarUrl: this.buildAvatarUrl(result.user.userData.picture),
				};
			}),
		);
	}

	public ngOnInit(): void {
		this.metadata.init();
		combineLatest({
			role: this.userRoleService.getRole(),
			vendor: this.vendorService.getVendor(),
			hasAccessToVendorDD: this._hasAccessToVendorDD$,
		})
			.pipe(untilDestroyed(this))
			.subscribe(result => {
				this.items = [];
				if (result.hasAccessToVendorDD) {
					this.items = [
						{
							label: 'Change Customer',
							command: () => {
								this.showVendorSelectorPopup();
							},
						},
					];
				}

				// Administrator
				if (result.role === Role.Administrator) {
					this.addAdminMenuItems(result.vendor);
				}

				//Manager
				if (result.role === Role.Manager) {
					this.addManagerMenuItems(result.vendor);
				}

				// User
				if (result.role === Role.User) {
					this.addUserMenuItems(result.vendor);
				}

				// All
				this.addCommonMenuItems(result.vendor);
			});
		this.vendorService
			.getVendor()
			.pipe(untilDestroyed(this))
			.subscribe(vendor => {
				this.selectedVendorId = vendor.id;
			});
	}

	private showVendorSelectorPopup() {
		if (!this.selectedVendorId) {
			return;
		}

		const ref = this.dialogService.open(VendorSelectorPopupComponent, {
			header: 'Select Customer',
			width: '350px',
			height: '150px',
			position: 'top',
			data: { vendorId: this.selectedVendorId },
		});

		ref.onClose.pipe(untilDestroyed(this)).subscribe((vendorId: number) => {
			if (vendorId) {
				this.vendorService.setVendor(vendorId);
			}
		});
	}

	private buildAvatarUrl(avatarFileName: string): any {
		if (avatarFileName == null) {
			//TODO: SergeyV - Change default user image
			return null;
		}
		return environment.resourceBaseAddress + '/avatars/' + avatarFileName;
	}

	private hasAccessToManyVendors(userData: any): boolean {
		if (TokenHelper.hasAdminRole(userData)) {
			return true;
		}

		const vendorIds = TokenHelper.getAvailableVendorIds(userData);

		return vendorIds.length > 1;
	}

	private addAdminMenuItems(vendor: IVendorModel) {

		this.items = this.items.concat([
			{
				label: 'Customer Profile',
				routerLink: this.navigationService.getEditCustomerRoute(vendor.id),
			},
		]);

		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{ label: 'Printers', url: this.navigationService.getAdminPrintersUrl(vendor.id) },
			]);
		}

		if (environment.oldSiteBaseAddress == null) {
			this.items = this.items.concat([
				{ label: 'Customers (tree products)', routerLink: this.navigationService.getCustomersList2Route() },
			]);
		}

		this.items = this.items.concat([
			{
				label: 'Printers V2',
				badge: this.badgeText,
				routerLink: this.navigationService.getAdminPrintersRoute(),
			},
			{ label: 'Label Layouts', routerLink: this.navigationService.getLabelLayoutsRoute() },
			{
				label: 'Handheld Readers',
				routerLink: this.navigationService.getHandheldReadersListRoute(),
			},
			{
				label: 'Fixed Readers',
				url: this.navigationService.getReadersUrl(vendor.id, true),
			},
		]);

		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{
					label: 'Mobile Users (old)',
					url: this.navigationService.getManagerUsersUrl(vendor.id),
				},
			]);
		}

		this.items = this.items.concat([
			{ label: 'Users', routerLink: this.navigationService.getAdminUsersRoute() },
		]);

		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{ label: 'Trials', url: this.navigationService.getAdminTrialsUrl(vendor.id) },
			]);
		}

		this.items = this.items.concat([
			{
				label: 'Customers',
				url: this.navigationService.getCustomersListRoute(),
				routerLink: this.navigationService.getCustomersListRoute(),
			},
			{
				label: 'Email History',
				routerLink: this.navigationService.getEmailHistoryRoute(),
				url: null,
			},
			{
				label: 'Location Framework',
				routerLink: null,
				url: this.navigationService.getLocationFramework(vendor.id),
			},
		]);

		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{ label: 'Internal Products', url: this.navigationService.getAdminInternalProductsUrl(vendor.id) },
			]);
		}
	}

	private addManagerMenuItems(vendor: IVendorModel) {

		this.items = this.items.concat([
			{
				label: 'Customer Profile',
				url: this.navigationService.getCustomerProfileRoute(),
			},
		]);

		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{ label: 'Printers', url: this.navigationService.getManagerPrintersUrl(vendor.id) },
			]);
		}

		this.items = this.items.concat([
			{
				label: 'Printers V2',
				badge: this.badgeText,
				routerLink: this.navigationService.getManagerPrintersRoute(),
			},
		]);


		this.items = this.items.concat([
			{ label: 'Label Layouts', routerLink: this.navigationService.getLabelLayoutsRoute() },
			{
				label: 'Fixed Readers',
				url: this.navigationService.getReadersUrl(vendor.id, false),
			},
		]);

		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{
					label: 'Mobile Users (old)',
					url: this.navigationService.getManagerUsersUrl(vendor.id),
				},
			]);
		}

		this.items = this.items.concat([
			{ label: 'Users', routerLink: this.navigationService.getManagerUsersRoute() },
			{
				label: 'Location Framework',
				routerLink: null,
				url: this.navigationService.getLocationFramework(vendor.id),
			},
		]);
	}

	private addUserMenuItems(vendor: IVendorModel) {
		if (environment.oldSiteBaseAddress != null) {
			this.items = this.items.concat([
				{
					label: 'Printers',
					url: this.navigationService.getManagerPrintersUrl(vendor.id),
				},
			]);
		}
	}

	private addCommonMenuItems(vendor: IVendorModel) {
		this.items = this.items.concat([
			{ label: 'Resources', url: this.navigationService.getResourcesUrl(vendor.id) },
			{
				label: 'Logout',
				icon: 'mdi mdi-logout',
				styleClass: 'border-top',
				command: () => {
					this.oidcSecurityService
						.logoff()
						.pipe(untilDestroyed(this))
						.subscribe(data => console.log(data));
				},
			},
		]);
	}

	public showPrintersSidebar() {
		this.isPrintersSidebarVisible = true;
	}
}

interface IUserDisplayInfo {
	userName: string;
	role: string;
	avatarUrl: string | null;
}
