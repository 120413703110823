<p-confirmDialog
	styleClass="confirm-message"
	[position]="position"
	[key]="key"
	[baseZIndex]="5000"
	[dismissableMask]="true"
>
	<ng-template let-message pTemplate="headless">
		<div class="toast-content-container">
			<div class="text-content">
				<i class="mdi mdi-alert-outline"></i>
				@if (message.message) {
					<p [innerHTML]="message.message"></p>
				}
				<h4>{{ message.header }}</h4>
			</div>
			<div class="toast-buttons">
				<app-button [variant]="buttonVariantEnum.Primary" [text]="confirmButtonText"
							(click)="message.accept()" />
				<app-button [variant]="buttonVariantEnum.Light" text="Cancel" (click)="message.reject()" />
			</div>
		</div>
	</ng-template>
</p-confirmDialog>
