import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import * as ExcelJS from 'exceljs';
import { Parser } from '@core/abstractions';

@Injectable({
	providedIn: 'root',
})
export class XlsxParserService extends Parser {
	public parse(arrayBuffer: ArrayBuffer): Observable<ExcelJS.Workbook> {
		const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();

		return from(workbook.xlsx.load(arrayBuffer));
	}
}
