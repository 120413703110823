import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { Observable, of } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Parser } from '@core/abstractions';

@Injectable({
	providedIn: 'root',
})
export class CsvParserService extends Parser {
	private parser: Papa;
	constructor() {
		super();
		this.parser = new Papa<string>();
	}

	public parse(arrayBuffer: ArrayBuffer): Observable<ExcelJS.Workbook> {
		const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();

		const fileReaderResult: string = new TextDecoder().decode(arrayBuffer);
		const worksheet = workbook.addWorksheet('CSV Data');

		this.parser.parse(fileReaderResult, {
			skipEmptyLines: true,
			step: result => {
				worksheet.addRow(result.data);
			},
		});

		return of(workbook);
	}
}
