import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ConnectionType, Dpi, State } from '@shared/enums';
import { OffsetPagination } from '@shared/models';
import { HttpUtils } from '@shared/util/http-utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PrintServicePrintStationService {
	private readonly printServiceUrl: string;
	private httpUtils: HttpUtils = new HttpUtils();

	constructor(private http: HttpClient) {
		this.printServiceUrl = environment.printServiceBaseAddress;
	}

	public getPrintStations(clientIds: string[] = []): Observable<IPrintStationResponse[]> {
		const params: HttpParams = this.httpUtils.getParams({
			...new OffsetPagination(0, clientIds.length),
			clientIds: clientIds,
		});
		return this.http.get<IPrintStationResponse[]>(`${this.printServiceUrl}/api/v1/stations`, { params: params });
	}
}

interface IPrinterUpdateRequest {
	notes?: string;
}

interface IPrinterResponse {
	id: number;
	name: string;
	serialNumber: string;
	status: State;
	displayName: string;
	dpi: Dpi;
	disconnected: Date;
	clientId: string;
	firmware: string;
	connectedBy: ConnectionType;
	issueDescription?: string;
	notes?: string;
}

interface IPrintStationResponse {
	clientId: string;
	name: string;
	status: State;
	alias: string;
	version: string;
	disconnected: Date;
}
