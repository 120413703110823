import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomErrors, OffsetCollection, OffsetPagination } from '@shared/models';
import { IProductMediaFilter, IProductMediaModel } from '@shared/models/products';
import { HttpUtils } from '@shared/util/http-utils';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProductMediaService {
	private _portalUrl: string;
	private _httpUtils: HttpUtils = new HttpUtils();

	constructor(private http: HttpClient) {
		this._portalUrl = environment.portalBaseAddress;
	}

	public getMany(
		filter: IProductMediaFilter,
		pager: OffsetPagination,
	): Observable<OffsetCollection<IProductMediaModel>> {
		const params: HttpParams = this._httpUtils.getParams({
			...pager,
			...filter,
		});
		return this.http
			.get<
				IProductMediaModel[]
			>(`${this._portalUrl}/api/v1/media/products/images`, { params: params, observe: 'response' })
			.pipe(
				map(resp => {
					return this._httpUtils.toOffsetCollection<IProductMediaModel>(resp);
				}),
			);
	}

	public getDetails(productId: number): Observable<IProductMediaModel | undefined> {
		const url = this._portalUrl + '/api/v1/media/products/' + productId + '/images';
		return this.http.get<IProductMediaModel>(url).pipe(
			catchError(err => {
				if (!!err.error?.key && !!err.error?.code && err.error.code == CustomErrors.ProductMediaNotFound) {
					return of(undefined);
				}

				throw err;
			}),
		);
	}

	public GetDefaultThumbnail(productId: number): string{
		const url= this._portalUrl + `/api/v1/media/products/` + productId + `/images/default/thumbnail`;
		return url;
	}
}
