import { CustomerOrderBy } from '@admin/customers/enums';
import { Injectable } from '@angular/core';
import { InventoryOrderField } from '@inventory/enums';
import { DisplayOrderType, OrderOrderBy, OrderType } from '@orders/enums';
import { PrintJobOrderBy, UserOrderBy } from '@shared/enums';
import { CalculateReorderOrderBy } from 'src/app/calculate-reorder/enums';
import { EmailOrderBy } from 'src/app/email/enums';
import { ScanSessionsOrderBy } from 'src/app/scan-sessions/enums';
import { HandheldReadersOrderBy } from '@shared/enums/handheld-readers-order-by';

@Injectable({
	providedIn: 'root',
})
export class TableHeaderService {
	public getOrdersHeaders(orderType: DisplayOrderType) {
		return [
			{
				displayName: 'Type',
				sortable: false,
				columnKey: 'Type',
				isVisible: true,
			},
			{
				sortFieldName: OrderOrderBy.OrderName,
				displayName: 'Order #',
				sortable: true,
				columnKey: OrderOrderBy.OrderName,
				isVisible: true,
			},
			{
				sortFieldName: OrderOrderBy.CreateDate,
				displayName: 'Create Date',
				sortable: true,
				columnKey: OrderOrderBy.CreateDate,
				isVisible: true,
			},
			{
				sortFieldName: OrderOrderBy.Origin,
				displayName: 'Origin',
				sortable: false,
				columnKey: OrderOrderBy.Origin,
				isVisible: true,
			},
			{
				isVisible: orderType != DisplayOrderType.Purchase,
				displayName: 'Shipped',
				sortable: false,
				columnKey: 'Shipped',
			},
			{
				sortFieldName: OrderOrderBy.Destination,
				displayName: 'Destination',
				sortable: false,
				columnKey: OrderOrderBy.Destination,
				isVisible: true,
			},
			{
				isVisible: orderType != DisplayOrderType.Sales,
				displayName: 'Received',
				sortable: false,
				columnKey: 'Received',
			},
			{
				sortFieldName: OrderOrderBy.Status,
				displayName: 'Status',
				sortable: false,
				columnKey: OrderOrderBy.Status,
				isVisible: true,
			},
			{
				displayName: 'Details',
				sortable: false,
				columnKey: 'Details',
				isVisible: true,
			},
		];
	}

	public getProductSearchHeaders(orderType: OrderType, isNewOrder: boolean) {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Image',
			},
			{
				isVisible: true,
				displayName: 'Title',
				sortable: false,
				columnKey: 'Title',
			},
			{
				isVisible: true,
				displayName: 'SKU',
				sortable: false,
				columnKey: 'Sku',
			},
			{
				isVisible: true,
				displayName: isNewOrder ? 'Order' : 'Ordered',
				sortable: false,
				tooltip: isNewOrder ? 'Order cases' : 'Ordered cases',
				columnKey: 'CaseCount',
			},
			{
				isVisible: true,
				displayName: 'IPC',
				sortable: false,
				columnKey: 'IPC',
				tooltip: 'Items per Case',
				styleClass: 't-right',
			},
			{
				isVisible: true,
				displayName: 'Items',
				sortable: false,
				columnKey: 'Items',
				styleClass: 't-right',
			},
			{
				isVisible: orderType != OrderType.Purchase && !isNewOrder,
				displayName: 'Shipped',
				sortable: false,
				tooltip: 'Shipped cases',
				columnKey: 'Progress',
			},
			{
				isVisible: orderType != OrderType.Sales && !isNewOrder,
				displayName: 'Received',
				sortable: false,
				tooltip: 'Received cases',
				columnKey: 'Progress',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Errors',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Actions',
			},
		];
	}

	public getAdminUsersHeaders() {
		return [
			{
				isVisible: true,
				sortFieldName: UserOrderBy.DisplayName,
				displayName: 'Name',
				sortable: true,
				columnKey: UserOrderBy.DisplayName,
			},
			{
				isVisible: true,
				sortFieldName: UserOrderBy.Email,
				displayName: 'Email',
				sortable: true,
				columnKey: UserOrderBy.Email,
			},
			{
				isVisible: true,
				sortFieldName: UserOrderBy.Role,
				displayName: 'Roles',
				sortable: false,
				columnKey: UserOrderBy.Role,
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'isUserActive',
			},
		];
	}

	public getManagerUsersHeaders() {
		return [
			{
				isVisible: true,
				sortFieldName: UserOrderBy.DisplayName,
				displayName: 'Name',
				sortable: true,
				columnKey: UserOrderBy.DisplayName,
			},
			{
				isVisible: true,
				sortFieldName: UserOrderBy.Email,
				displayName: 'Email',
				sortable: true,
				columnKey: UserOrderBy.Email,
			},
			{
				isVisible: true,
				sortFieldName: UserOrderBy.Role,
				displayName: 'Roles',
				sortable: false,
				columnKey: UserOrderBy.Role,
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'isUserActive',
			},
		];
	}

	public getCustomersHeaders() {
		return [
			{
				isVisible: true,
				sortFieldName: CustomerOrderBy.Id,
				displayName: 'Id',
				sortable: true,
				columnKey: CustomerOrderBy.Id,
			},
			{
				isVisible: true,
				sortFieldName: CustomerOrderBy.Name,
				displayName: 'Name',
				sortable: true,
				columnKey: CustomerOrderBy.Name,
			},
		];
	}

	public getEmailHistoryHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'IsRead',
			},
			{
				isVisible: true,
				sortFieldName: EmailOrderBy.To,
				displayName: 'Sent To',
				sortable: true,
				columnKey: EmailOrderBy.To,
			},
			{
				isVisible: true,
				sortFieldName: EmailOrderBy.SentDate,
				displayName: 'Sent Date',
				sortable: true,
				columnKey: EmailOrderBy.SentDate,
			},
			{
				isVisible: true,
				sortFieldName: EmailOrderBy.CreatedDate,
				displayName: 'Created Date',
				sortable: true,
				columnKey: EmailOrderBy.CreatedDate,
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Attachments',
			},
			{
				isVisible: true,
				sortFieldName: EmailOrderBy.Subject,
				displayName: 'Subject',
				sortable: true,
				columnKey: EmailOrderBy.Subject,
			},
		];
	}

	public getAdminStationsHeaders() {
		return [
			{
				isVisible: true,
				displayName: 'Customer',
				sortable: false,
				columnKey: 'Customer',
			},
			{
				isVisible: true,
				displayName: 'Location',
				sortable: false,
				columnKey: 'LocationName',
			},
			{
				isVisible: true,
				displayName: 'PC',
				sortable: false,
				columnKey: 'PC',
				icon: 'mdi mdi-wifi static-icon mr-3',
			},
			{
				isVisible: true,
				displayName: 'Printer',
				sortable: false,
				columnKey: 'Printer',
				icon: 'mdi mdi-printer static-icon mr-3',
			},
			{
				isVisible: true,
				displayName: 'Lock',
				sortable: false,
				columnKey: 'Lock',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Settings',
			},
		];
	}

	public getManagerStationsHeaders() {
		return [
			{
				isVisible: true,
				displayName: 'Location',
				sortable: false,
				columnKey: 'LocationName',
			},
			{
				isVisible: true,
				displayName: 'PC',
				sortable: false,
				columnKey: 'PC',
				icon: 'mdi mdi-wifi static-icon mr-3',
			},
			{
				isVisible: true,
				displayName: 'Printer',
				sortable: false,
				columnKey: 'Printer',
				icon: 'mdi mdi-printer static-icon mr-3',
			},
			{
				isVisible: true,
				displayName: 'Lock',
				sortable: false,
				columnKey: 'Lock',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Settings',
			},
		];
	}

	public getPrintJobsHeaders() {
		return [
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.ReferenceName,
				displayName: 'Ref',
				sortable: true,
				columnKey: PrintJobOrderBy.ReferenceName,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.JobNumber,
				displayName: 'Job',
				sortable: true,
				columnKey: PrintJobOrderBy.JobNumber,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.LocationName,
				displayName: 'Location',
				sortable: true,
				columnKey: PrintJobOrderBy.LocationName,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.UserName,
				displayName: 'User',
				sortable: true,
				columnKey: PrintJobOrderBy.UserName,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.LabelName,
				displayName: 'Layout',
				sortable: true,
				columnKey: PrintJobOrderBy.LabelName,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.PrinterName,
				displayName: 'Printer',
				sortable: true,
				columnKey: PrintJobOrderBy.PrinterName,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.Started,
				displayName: 'Started/Finished',
				sortable: true,
				columnKey: PrintJobOrderBy.Started,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.Status,
				displayName: 'Printing Status',
				sortable: true,
				columnKey: PrintJobOrderBy.Status,
			},
			{
				isVisible: true,
				sortFieldName: PrintJobOrderBy.DocumentsCount,
				displayName: 'Tags',
				sortable: true,
				columnKey: PrintJobOrderBy.DocumentsCount,
			},
			{ isVisible: true, displayName: 'Actions', sortable: false, columnKey: 'Actions' },
		];
	}

	public getCreatePrintJobHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Image',
			},
			{
				isVisible: true,
				displayName: 'Title',
				sortable: false,
				columnKey: 'Title',
			},
			{
				isVisible: true,
				displayName: 'SKU',
				sortable: false,
				columnKey: 'Sku',
			},
			{
				isVisible: true,
				displayName: 'Print Tags',
				sortable: false,
				columnKey: 'PrintTags',
			},
			{
				isVisible: true,
				displayName: 'IPC',
				sortable: false,
				columnKey: 'IPC',
				tooltip: 'Items per Case',
				styleClass: 't-right',
			},
			{
				isVisible: true,
				displayName: 'Items',
				sortable: false,
				columnKey: 'Items',
				styleClass: 't-right',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Errors',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Actions',
			},
		];
	}

	public getCreatePrintJobFromOrderHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Image',
			},
			{
				isVisible: true,
				displayName: 'Title',
				sortable: false,
				columnKey: 'Title',
			},
			{
				isVisible: true,
				displayName: 'SKU',
				sortable: false,
				columnKey: 'Sku',
			},
			{
				isVisible: true,
				displayName: 'Ordered',
				sortable: false,
				tooltip: 'Ordered cases',
				columnKey: 'CaseCount',
			},
			{
				isVisible: true,
				displayName: 'Received',
				tooltip: 'Received cases',
				sortable: false,
				columnKey: 'VerifiedCaseCount',
			},
			{
				isVisible: true,
				displayName: 'Print Tags',
				sortable: false,
				columnKey: 'PrintTags',
			},
			{
				isVisible: true,
				displayName: 'IPC',
				tooltip: 'Items per Case',
				sortable: false,
				columnKey: 'IPC',
				styleClass: 't-right',
			},
			{
				isVisible: true,
				displayName: 'Items',
				sortable: false,
				columnKey: 'Items',
				styleClass: 't-right',
			},
		];
	}

	public getPrintJobTemplatesHeaders() {
		return [
			{
				isVisible: true,
				displayName: 'Product Title',
				sortable: false,
				columnKey: 'product-title',
			},
			{
				isVisible: true,
				displayName: 'SKU',
				sortable: false,
				columnKey: 'sku',
			},
			{
				isVisible: true,
				displayName: 'ISN`s',
				sortable: false,
				columnKey: 'isns',
			},
			{
				isVisible: true,
				displayName: 'Success',
				sortable: false,
				columnKey: 'success',
			},
		];
	}

	public getPrintJobDocumentsHeaders() {
		return [
			{
				isVisible: true,
				displayName: 'EPC',
				sortable: false,
				columnKey: 'epc',
			},
			{
				isVisible: true,
				displayName: 'Serial Number',
				sortable: false,
				columnKey: 'serialNumber',
			},
			{
				isVisible: true,
				displayName: 'Status',
				sortable: false,
				columnKey: 'status',
			},
		];
	}

	public getStationSettingsHeaders() {
		return [
			{
				isVisible: true,
				displayName: 'Model',
				sortable: false,
				columnKey: 'Name',
			},
			{
				isVisible: true,
				displayName: 'Display Name',
				sortable: false,
				columnKey: 'DisplayName',
			},
			{
				isVisible: true,
				displayName: 'Serial Number',
				sortable: false,
				columnKey: 'SerialNumber',
			},
			{
				isVisible: true,
				displayName: 'Firmware version',
				sortable: false,
				columnKey: 'Firmware',
			},
			{
				isVisible: true,
				displayName: 'Status',
				sortable: false,
				columnKey: 'Status',
			},
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Actions',
			},
		];
	}

	public getInventoryBundlesHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Img',
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Title,
				displayName: 'Title',
				sortable: true,
				columnKey: InventoryOrderField.Title,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Count,
				displayName: 'Count',
				sortable: true,
				columnKey: InventoryOrderField.Count,
			},

			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Actions',
				styleClass: 't-right',
			},
		];
	}

	public getInventoryProductsHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'RowToggle',
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Title,
				displayName: 'Title',
				sortable: true,
				columnKey: InventoryOrderField.Title,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Sku,
				displayName: 'SKU',
				sortable: true,
				columnKey: InventoryOrderField.Sku,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Brand,
				displayName: 'Brand',
				sortable: true,
				columnKey: InventoryOrderField.Brand,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Variants,
				displayName: 'Variants',
				sortable: true,
				columnKey: InventoryOrderField.Variants,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Tags,
				displayName: 'Tags',
				sortable: true,
				columnKey: InventoryOrderField.Tags,
			},

			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Actions',
				styleClass: 't-right',
			},
		];
	}

	public getInventoryTagsHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Img',
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Title,
				displayName: 'Title',
				sortable: true,
				columnKey: InventoryOrderField.Title,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Sku,
				displayName: 'SKU',
				sortable: true,
				columnKey: InventoryOrderField.Sku,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Price,
				displayName: 'Price',
				sortable: true,
				columnKey: InventoryOrderField.Price,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.LocationName,
				displayName: 'Location',
				sortable: true,
				columnKey: InventoryOrderField.LocationName,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Isn,
				displayName: 'ISN',
				sortable: true,
				columnKey: InventoryOrderField.Isn,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.VerifiedDate,
				displayName: 'Verified date',
				sortable: true,
				columnKey: InventoryOrderField.VerifiedDate,
			},
			{
				isVisible: true,
				sortFieldName: InventoryOrderField.Comments,
				displayName: 'Comments',
				sortable: true,
				columnKey: InventoryOrderField.Comments,
			},

			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Actions',
				styleClass: 't-right',
			},
		];
	}

	public getScanSessionsHeaders() {
		return [
			{
				isVisible: true,
				displayName: 'Location',
				sortable: false,
				columnKey: 'Location',
			},
			{
				isVisible: true,
				sortFieldName: ScanSessionsOrderBy.User,
				displayName: 'User',
				sortable: true,
				columnKey: ScanSessionsOrderBy.User,
			},
			{
				isVisible: true,
				displayName: 'Comments',
				sortable: false,
				columnKey: 'Comments',
			},
			{
				isVisible: true,
				sortFieldName: ScanSessionsOrderBy.ScanType,
				displayName: 'Scan Type',
				sortable: true,
				columnKey: ScanSessionsOrderBy.ScanType,
			},
			{
				isVisible: true,
				displayName: 'Tags',
				sortable: false,
				columnKey: 'Tags',
				styleClass: 't-center',
			},
			{
				isVisible: true,
				sortFieldName: ScanSessionsOrderBy.UploadTime,
				displayName: 'Upload Time',
				sortable: true,
				columnKey: ScanSessionsOrderBy.UploadTime,
			},
			{
				isVisible: true,
				displayName: 'TST',
				sortable: false,
				columnKey: 'TST',
				tooltip: 'Total Scan Time',
				tooltipPosition: 'left',
			},
			{
				isVisible: true,
				displayName: 'Report',
				sortable: false,
				columnKey: 'Actions',
				styleClass: 't-center',
			},
		];
	}

	public getCalculateReorderHeaders() {
		return [
			{
				isVisible: true,
				displayName: '',
				sortable: false,
				columnKey: 'Image',
			},
			{
				isVisible: true,
				sortFieldName: CalculateReorderOrderBy.Title,
				displayName: 'Title',
				sortable: true,
				columnKey: CalculateReorderOrderBy.Title,
			},
			{
				isVisible: true,
				displayName: 'SKU',
				sortable: false,
				columnKey: 'Sku',
			},
			{
				isVisible: true,
				sortFieldName: CalculateReorderOrderBy.Incoming,
				displayName: 'Incoming',
				sortable: true,
				columnKey: CalculateReorderOrderBy.Incoming,
			},
			{
				isVisible: true,
				sortFieldName: CalculateReorderOrderBy.Verified,
				displayName: 'Verified',
				sortable: true,
				columnKey: CalculateReorderOrderBy.Verified,
			},
			{
				isVisible: true,
				sortFieldName: CalculateReorderOrderBy.Min,
				displayName: 'Min',
				sortable: true,
				columnKey: CalculateReorderOrderBy.Min,
			},
			{
				isVisible: true,
				sortFieldName: CalculateReorderOrderBy.Max,
				displayName: 'Max',
				sortable: true,
				columnKey: CalculateReorderOrderBy.Max,
			},
			{
				isVisible: true,
				sortFieldName: CalculateReorderOrderBy.Order,
				displayName: 'Order',
				sortable: true,
				columnKey: CalculateReorderOrderBy.Order,
			},
		];
	}

	public getHandheldReadersHeaders() {
		return [
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.ReaderModel,
				displayName: 'Reader Model',
				sortable: false,
				columnKey: HandheldReadersOrderBy.ReaderModel,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.SerialNumber,
				displayName: 'Serial Number',
				sortable: false,
				columnKey: HandheldReadersOrderBy.SerialNumber,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.Customer,
				displayName: 'Customer',
				sortable: false,
				columnKey: HandheldReadersOrderBy.Customer,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.FirstConnected,
				displayName: 'First connected',
				sortable: true,
				columnKey: HandheldReadersOrderBy.FirstConnected,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.LastConnected,
				displayName: 'Last connected',
				sortable: true,
				columnKey: HandheldReadersOrderBy.LastConnected,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.Location,
				displayName: 'Location',
				sortable: false,
				columnKey: HandheldReadersOrderBy.Location,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.UserName,
				displayName: 'User Name',
				sortable: false,
				columnKey: HandheldReadersOrderBy.UserName,
			},
			{
				isVisible: true,
				sortFieldName: HandheldReadersOrderBy.Firmware,
				displayName: 'Firmware',
				sortable: false,
				columnKey: HandheldReadersOrderBy.Firmware,
			},
		];
	}
}
