import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { IExcel } from '@core/models';
import { FileSelectEvent } from 'primeng/fileupload';
import { fromEvent, switchMap } from 'rxjs';
import { ParserFactoryService } from '@core/services';
import { Parser } from '@core/abstractions';

@Directive({
	selector: '[excelReader]',
})
export class ExcelReaderDirective {
	@Output() dataChanged = new EventEmitter<IExcel>();
	private _reader: FileReader;
	private _fileName!: string;
	private _fileType!: string;

	constructor(
		private el: ElementRef<HTMLInputElement>,
		private parserFactoryService: ParserFactoryService,
	) {
		this._reader = new FileReader();

		fromEvent(this._reader, 'loadend')
			.pipe(
				switchMap(_ => {
					const reader = _.target as FileReader;

					const parser: Parser = parserFactoryService.getParserByFileType(this._fileType);

					return parser.parse(reader.result as ArrayBuffer);
				}),
			)
			.subscribe(_ => {
				el.nativeElement.value = '';
				this.dataChanged.emit({ workbook: _, fileName: this._fileName });
			});
	}

	@HostListener('onSelect', ['$event']) onFileSelected(event: FileSelectEvent) {
		if (event.currentFiles.length > 0) {
			this._fileName = event.currentFiles[0].name;
			this._fileType = event.currentFiles[0].type;
			this._reader.readAsArrayBuffer(event.currentFiles[0]);
		}
	}
}
