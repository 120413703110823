export class CaseInsensitiveRecord<T> {
    private data: Record<string, T> = {};

    constructor() {
        return new Proxy(this, {
            get: (target, key: string) => {
                return target.data[key.toLowerCase()];
            },
            set: (target, key: string, value: T) => {
                target.data[key.toLowerCase()] = value;
                return true;
            }
        });
    }

    [key: string]: any;
}
