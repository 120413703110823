@if (!tooltip) {
	<button
		[disabled]="isDisabled"
		[routerLink]="onRouterLink"
		(click)="handleClick()"
		class="{{ customClass }} rfid-btn"
		[ngClass]="{
			'rfid-icon-btn': variant === buttonVariantEnum.Icon,
			'rfid-btn-light': variant === buttonVariantEnum.Light,
			'rfid-btn-primary': variant === buttonVariantEnum.Primary,
			'rfid-btn-danger': variant === buttonVariantEnum.Danger,
		}"
	>
		@if (icon) {
			<i class="{{ icon }}"></i>
		}
		{{ text }}
	</button>
} @else {
	<button
		[disabled]="isDisabled"
		[pTooltip]="tooltip"
		[tooltipPosition]="tooltipAlign"
		[routerLink]="onRouterLink"
		(click)="handleClick()"
		class="{{ customClass }} rfid-btn"
		[ngClass]="{
			'rfid-btn-light': variant === buttonVariantEnum.Light,
			'rfid-btn-primary': variant === buttonVariantEnum.Primary,
			'rfid-btn-danger': variant === buttonVariantEnum.Danger,
		}"
	>
		@if (icon) {
			<i class="{{ icon }}"></i>
		}
		{{ text }}
	</button>
}
