import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
	IPrintStationDetailsModel,
	IPrintStationDetailsResponse,
	IPrintStationLocationDataRequest,
	IPrintStationLocationDataResponse,
} from '@printing/models';
import { ChunkService } from '@shared/services';
import { Observable } from 'rxjs';
import { OffsetCollection, OffsetPagination } from '@shared/models';

@Injectable({
	providedIn: 'root',
})
export class PortalPrintStationService {
	private portalUrl: string;

	constructor(
		private http: HttpClient,
		private chunkService: ChunkService,
	) {
		this.portalUrl = environment.portalBaseAddress;
	}

	public getDetails(id: number): Observable<IPrintStationDetailsModel> {
		return this.http.get<IPrintStationDetailsResponse>(`${this.portalUrl}/api/v1/printing/stations/${id}`);
	}

	public getMany(
		pager: OffsetPagination,
		filter: IPrintStationLocationDataRequest,
	): Observable<OffsetCollection<IPrintStationLocationDataResponse>> {
		const url = `${this.portalUrl}/api/v1/printing/stations`;
		return this.chunkService.get<IPrintStationLocationDataResponse, IPrintStationLocationDataRequest>(
			pager,
			filter,
			url,
		);
	}
	public getAll(
		request: IPrintStationLocationDataRequest,
	): Observable<OffsetCollection<IPrintStationLocationDataResponse>> {
		const url = `${this.portalUrl}/api/v1/printing/stations`;
		return this.chunkService.getAll<IPrintStationLocationDataResponse, IPrintStationLocationDataRequest>(
			request,
			url,
		);
	}
}
