import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseXlsxImport } from '@core/abstractions';
import { PrintProductProperty } from '@core/enums';
import { IExcelHeaders, IStoredHeaderSettings, PrintingProductHeadersModel } from '@core/models';
import {
	DocumentHeadersService,
	PrintingProductExcelService,
	PrintingProductStoredHeadersService,
	ProductValidatorService,
} from '@core/services';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonVariant } from '@shared/components';
import { IHeader } from '@shared/models/iheader';
import { ProductViewModel } from '@shared/models/products';
import { EMPTY, Subject, catchError, exhaustMap } from 'rxjs';
import * as fs from 'file-saver';

@UntilDestroy()
@Component({
	selector: 'excel-reader',
	templateUrl: './printing-product-excel-reader.component.html',
	styleUrls: ['./printing-product-excel-reader.component.scss'],
	providers: [{ provide: 'defaultHeaders', useValue: 'this._defaultHeader' }],
})
export class PrintingProductExcelReaderComponent extends BaseXlsxImport {
	@Input() products: ProductViewModel[] = [];
	@Output() productsChange = new EventEmitter<ProductViewModel[]>();
	public buttonVariantEnum = ButtonVariant;

	public readHeaders: IExcelHeaders | null = null;
	public printProductProperty = PrintProductProperty;

	public defaultHeader: IHeader[];
	public headersModel: PrintingProductHeadersModel = new PrintingProductHeadersModel();
	public rerenderTrigger: number = 1;
	private onDownloadTemplate$: Subject<void> = new Subject<void>();

	constructor(
		public excelService: PrintingProductExcelService,
		private productValidatorService: ProductValidatorService,
		documentHeadersService: DocumentHeadersService,
		private _storedHeadersService: PrintingProductStoredHeadersService,
	) {
		super(documentHeadersService);
		this.defaultHeader = this.excelService.headers;

		this.onDownloadTemplate$
			.pipe(
				untilDestroyed(this),
				exhaustMap(() => this.excelService.getTemplate()),
			)
			.subscribe(_ => {
				fs(_.file, _.fileName);
			});
	}

	public readExcel(headers: Record<string, number>) {
		if (this.selectedWorkbook) {
			this.excelReading = true;
			this.excelService
				.readData(this.selectedWorkbook, headers, this.hasColumnTitles)
				.pipe(
					catchError((err, caught) => {
						this.excelErrors = ['Something went wrong. Please send the Excel file to the administrator.'];
						this.excelReading = false;
						return EMPTY;
					}),
					untilDestroyed(this),
				)
				.subscribe((products: ProductViewModel[]) => {
					products.forEach(_ => this.productValidatorService.validate(_));
					this.finalizeReading();
					this.productsChange.emit(products);
				});
		}
	}

	public downloadTemplate() {
		this.onDownloadTemplate$.next();
	}

	public updateTable() {
		//TODO: workaround to rerender p-dropdown, it will not change the selected values from the code. Maybe it will be fixed in the next versions of primang
		this.rerenderTrigger++;
	}

	protected override getHeaders(): IStoredHeaderSettings {
		return this._storedHeadersService.get();
	}

	protected override setHeaders(headers: IStoredHeaderSettings): void {
		this._storedHeadersService.set(headers);
	}
	protected onClear() {
		this.excelErrors = [];
		this.headersModel = new PrintingProductHeadersModel();
		this.selectedWorkbook = null;
	}
}
