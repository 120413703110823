import { Injectable } from '@angular/core';
import { FiltersService } from '@shared/services/filters.service';
import { UserContextService } from '@shared/services/user-context.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserFiltersService {
	private storageKey = 'userAppFilter';
	constructor(
		private filterService: FiltersService,
		private userContextService: UserContextService,
	) {}

	private getUserSpecificKey(componentKey: string): string {
		const userId = this.userContextService.getUserId();
		return `${userId}_${componentKey}`;
	}

	public setFilters<T>(componentKey: string, filters: T): Observable<T> {
		const userSpecificKey = this.getUserSpecificKey(componentKey);
		return this.filterService.setFilters<T>(userSpecificKey, filters);
	}

	public getFilters<T>(componentKey: string): Observable<T | null> {
		const userSpecificKey = this.getUserSpecificKey(componentKey);
		return this.filterService.getFilters<T>(userSpecificKey);
	}
}
