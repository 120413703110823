import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUpdateStatusRequest } from '@core/models';
import { PrintingService } from '@core/services';
import { environment } from '@environments/environment';
import {
	ICreatePrintJobModel,
	IPrintJobFilter,
	IPrintJobModel,
	IPrintProductDocumentFilter,
	IPrintProductDocumentModel,
	IPrintProductTemplateModel,
	IPrintProductTemplateRequest,
	IStartJobModel,
} from '@printing/models';
import { PrintJobOrderBy, PrintingDocumentStatus } from '@shared/enums';
import { IBaseFilter, OffsetCollection, OffsetPagination } from '@shared/models';
import { ChunkService } from '@shared/services';
import { HttpUtils } from '@shared/util/http-utils';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PortalPrintJobService {
	private portalUrl: string;
	private httpUtils: HttpUtils = new HttpUtils();
	private printServiceUrl: string;

	constructor(
		private http: HttpClient,
		private chunkService: ChunkService,
		private printingService: PrintingService,
	) {
		this.portalUrl = environment.portalBaseAddress;
		this.printServiceUrl = environment.printServiceBaseAddress;
	}

	public create(request: ICreatePrintJobModel) {
		return this.http.post(`${this.portalUrl}/api/v1/printing/jobs`, request);
	}

	public getMany(filter: IPrintJobFilter, pager: OffsetPagination): Observable<OffsetCollection<IPrintJobModel>> {
		const request = this.toRequest(filter);

		const params: HttpParams = this.httpUtils.getParams({
			...pager,
			...request,
		});
		return this.http
			.get<IPrintJobModel[]>(`${this.portalUrl}/api/v1/printing/jobs`, { params: params, observe: 'response' })
			.pipe(
				map(resp => {
					return this.httpUtils.toOffsetCollection<IPrintJobModel>(resp);
				}),
			);
	}

	public getManyTemplates(
		request: IPrintProductTemplateRequest,
		printJobId: number,
		pager: OffsetPagination,
	): Observable<OffsetCollection<IPrintProductTemplateModel>> {
		const url = `${this.portalUrl}/api/v1/printing/jobs/${printJobId}/templates/`;
		return this.chunkService.get<IPrintProductTemplateModel, IPrintProductTemplateRequest>(pager, request, url);
	}

	public getManyDocuments(
		request: IPrintProductDocumentFilter,
		printJobId: number,
		pager: OffsetPagination,
	): Observable<OffsetCollection<IPrintProductDocumentModel>> {
		const url = `${this.portalUrl}/api/v1/printing/jobs/${printJobId}/documents/`;
		return this.chunkService.get<IPrintProductDocumentModel, IPrintProductDocumentFilter>(pager, request, url);
	}

	public startJob(model: IStartJobModel): Observable<Object> {
		const request: IUpdateStatusRequest = {
			groupId: model.printingGroupId,
			printingStatuses: model.statuses,
		};

		return this.http.put(
			`${this.printServiceUrl}/api/v1/stations/${model.clientId}/printers/${model.printerSerialNumber}/queue/start`,
			request,
		);
	}

	public cancelJob(model: IStartJobModel): Observable<Object> {
		const request: IUpdateStatusRequest = {
			groupId: model.printingGroupId,
			printingStatuses: model.statuses,
		};

		return this.http.put(
			`${this.printServiceUrl}/api/v1/stations/${model.clientId}/printers/${model.printerSerialNumber}/queue/failed`,
			request,
		);
	}

	public suspend(model: IStartJobModel): Observable<any> {
		const request: IUpdateStatusRequest = {
			groupId: model.printingGroupId,
			printingStatuses: model.statuses,
		};
		return this.printingService.pause(model.clientId, model.printerSerialNumber, request);
	}

	private toRequest(filter: IPrintJobFilter): IPrintJobRequest {
		const request: IPrintJobRequest = {
			orderBy: filter.orderBy,
			orderDirection: filter.orderDirection,
			vendorId: filter.vendorId,
			locationKeys: filter.locationKeys,
		};

		if (!!filter.keyword) {
			request.keyword = filter.keyword;
		}

		if (!!filter.printingStatuses) {
			request.printingStatuses = filter.printingStatuses;
		}

		if (!!filter.referenceName) {
			request.referenceName = filter.referenceName;
		}

		return request;
	}
}

interface IPrintJobRequest extends IBaseFilter<PrintJobOrderBy> {
	vendorId: number;
	keyword?: string;
	printingStatuses?: PrintingDocumentStatus[];
	referenceName?: string;
	locationKeys: string[];
}
