<p-sidebar (onHide)="onHide()" position="right" styleClass="sidebar-container" [(visible)]="isVisible">
	@if (printers$ | async; as printers) {
		<ng-template pTemplate="header"><h3>Available Printers</h3></ng-template>
		<div>
			<ul class="sidebar-printers-list">
				@for (printer of printers; track printer) {
					<li class="sidebar-printers-list-item">
						<div class="printer-info">
							<div class="img-box">
								<img
									[ngClass]="{
										'station-offline--opacity': printer.status == printerStateEnum.Offline,
									}"
									[src]="printer.imgUrl"
									alt="Printer image"
								/>
								<span class="status-marker" [ngClass]="'bgc-' + printer.status"></span>
							</div>
							<div class="details">
								<div class="printer-name">{{ printer.displayName }}</div>
								<div>{{ printer.locationName }}</div>
								<div class="badge status" [ngClass]="'bgc-' + printer.printStationStatus">
									PC {{ printer.printStationStatus }}
								</div>
							</div>
						</div>
						<div class="actions">
							@if (printer.issueDescription) {
								<i
									[pTooltip]="printer.issueDescription"
									tooltipPosition="left"
									class="mdi mdi-alert-outline static-icon warning mr-2"
								></i>
							}
							<!-- Will be added in the next release -->
							<!-- <i
							pTooltip="Pause all Print Jobs"
							tooltipPosition="left"
							class="action-icon mdi mdi-pause-box-outline"
							(click)="pausePrintJobs()"
						></i> -->
							@if (printer.status == printerStateEnum.Online) {
								<i
									pTooltip="Calibrate Printer"
									tooltipPosition="left"
									class="action-icon mdi mdi-target-variant"
									(click)="showCalibrateConfirm(printer)"
								></i>
							}
							<i
								pTooltip="Printer settings"
								tooltipPosition="left"
								class="action-icon mdi mdi-cog-outline"
								[routerLink]="navigationService.getPrinterSettingsRoute(printer.id)"
							></i>
						</div>
					</li>
				}
			</ul>
		</div>
	}

	<confirm-dialog
		[position]="confirmMessagePositionEnum.center"
		confirmButtonText="Calibrate"
		summary="Calibration may take up to 5 minutes. <br>
			Attention! Some tags will be printed during calibration. <br>
			Do not turn printer power off! <br>
			Do not start printing!"
		detail="Would you like to start Printer calibration?"
		[(visible)]="isCalibrateConfirmVisible"
		(onConfirm)="calibrate()"
	></confirm-dialog>
</p-sidebar>

@if (isSpinnerShown) {
	<spinner [overlay]="true"></spinner>
}
<p-toast key="calibrate" />
