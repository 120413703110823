import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CoreModule } from '@core/core.module';
import LayoutModule from '@layout/layout.module';
import * as Sentry from '@sentry/angular';

import { MetaDataService } from '@shared/services';
import SharedModule from '@shared/shared.module';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { AuthConfigModule } from 'src/app/auth-config.module';

import { GlobalErrorHandler } from '@core/services';
import { environment } from '@environments/environment';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		HttpClientModule,
		AppRoutingModule,
		AuthConfigModule,
		LayoutModule,
		CoreModule,
		ToastModule,
		MessageModule,
		NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
		NgxGoogleAnalyticsRouterModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		MetaDataService,
		MessageService,
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
