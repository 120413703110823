import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import {
	IPrinterViewModel,
	IPrintStationLocationDataRequest,
	IPrintStationLocationDataResponse,
} from '@printing/models';
import { RecordHelper } from '@shared/util';
import { IPrinterFilter } from '@core/models';
import { OffsetPagination } from '@shared/models';
import { LocationNameService } from '@core/services/location';
import { MetaDataService } from '@shared/services';
import { PrintServicePrintStationService, PortalPrintStationService, PrinterService } from '@printing/services';

@Injectable({
	providedIn: 'root',
})
export class PrinterFacadeService {
	constructor(
		private locationNameService: LocationNameService,
		private metaDataService: MetaDataService,
		private printerService: PrinterService,
		private printStationService: PrintServicePrintStationService,
		private portalPrintStationService: PortalPrintStationService,
	) {}

	public getAllPrinters(vendorId: number): Observable<IPrinterViewModel[]> {
		const request: IPrintStationLocationDataRequest = {
			vendorId: vendorId,
			clientIds: [],
			ids: [],
			hasLocationAssigned: true,
		};

		return this.portalPrintStationService.getAll(request).pipe(
			switchMap(stations => {
				const clientIds: string[] = stations.map(_ => _.clientId);
				if (clientIds.length == 0) {
					return of([]);
				}

				const clientLocationDictionary = stations.reduce<Record<string, IPrintStationLocationDataResponse>>(
					(previous, current) => {
						previous[current.clientId] = current;
						return previous;
					},
					{},
				);

				//we need to wait for the locations to load. in order to assign the location names to the printers
				const locations$ = this.metaDataService.getLocations();
				const printers$ = this.printerService.getAllPrinters(clientIds);
				const stations$ = this.printStationService.getPrintStations(clientIds);
				return combineLatest({
					locations: locations$,
					printers: printers$,
					stations: stations$,
				}).pipe(
					filter(_ => {
						return _.locations.length > 0;
					}),
					map(response => {
						return response.printers
							.filter(_ => clientIds.includes(_.clientId))
							.map<IPrinterViewModel>(printer => {
								const stationsDictionary = RecordHelper.toRecord(response.stations, 'clientId');
								const stationLocationData = clientLocationDictionary[printer.clientId];
								const location = this.locationNameService.findLocationByKey(
									stationLocationData.locationKey!,
								);
								const station = stationsDictionary[printer.clientId];
								return {
									...printer,
									locationKey: stationLocationData.locationKey!,
									locationType: location?.type ?? 'N/A',
									locationName: location?.name ?? 'N/A',
									printStationName: stationLocationData.displayName,
									printStationId: stationLocationData.id!,
									printStationStatus: station.status,
								};
							});
					}),
				);
			}),
		);
	}

	public getPrinterById(id: number, customerId: number): Observable<IPrinterViewModel> {
		const printerFilter: IPrinterFilter = {
			ids: [id],
			clientIds: [],
			serialNumbers: [],
		};

		return this.printerService.getManyPrinters(printerFilter, OffsetPagination.First()).pipe(
			map(_ => _[0]),
			switchMap(printer => {
				const printStation$ = this.printStationService
					.getPrintStations([printer.clientId])
					.pipe(map(_ => _[0]));

				const request: IPrintStationLocationDataRequest = {
					//Todo: Rename vendorId to customerId after global renaming vendor to customer
					vendorId: customerId,
					clientIds: [printer.clientId],
					ids: [],
					hasLocationAssigned: true,
				};

				const printerLocation$ = this.portalPrintStationService
					.getMany(OffsetPagination.First(), request)
					.pipe(map(_ => _[0]));

				return combineLatest({
					printStation: printStation$,
					printerLocation: printerLocation$,
				}).pipe(
					map(response => {
						const location = this.locationNameService.findLocationByKey(
							response.printerLocation.locationKey!,
						);
						const a: IPrinterViewModel = {
							...printer,
							locationKey: response.printerLocation.locationKey!,
							locationName: location?.name ?? 'N/A',
							locationType: location?.type ?? 'N/A',
							printStationId: response.printerLocation.id!,
							printStationName: response.printStation.name,
							printStationStatus: response.printStation.status,
						};
						return a;
					}),
				);
			}),
		);
	}
}
