import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FiltersService {
	private storageKey = 'appFilters';

	private filtersSubject = new Subject<any>();

	setFilters<T>(componentKey: string, filters: T): Observable<T> {
		let filterStates = JSON.parse(sessionStorage.getItem(this.storageKey) || '{}');

		filterStates[componentKey] = filters;

		sessionStorage.setItem(this.storageKey, JSON.stringify(filterStates));

		this.filtersSubject.next(filterStates);

		return this.filtersSubject.asObservable();
	}

	getFilters<T>(userSpecificKey: string): Observable<T | null> {
		return new Observable<T | null>(observer => {
			let filterStates = JSON.parse(sessionStorage.getItem(this.storageKey) || '{}');
			observer.next(filterStates[userSpecificKey] || null);
			observer.complete();
		});
	}
}
