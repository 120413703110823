import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ConfirmMessagePosition } from '@shared/enums';
import { ButtonVariant } from '@shared/components';
import { ConfirmationService } from 'primeng/api';

@Component({
	selector: 'confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	providers: [ConfirmationService],
})
export class ConfirmDialogComponent implements OnDestroy {
	public key = crypto.randomUUID();
	public buttonVariantEnum = ButtonVariant;

	@Input() set visible(val: boolean) {
		if (val) {
			this.showConfirm();
		}
	}

	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() position: ConfirmMessagePosition = ConfirmMessagePosition.center;

	@Input() confirmButtonText: string = 'Yes';
	@Input() summary: string | undefined;
	@Input() detail: string | undefined;

	@Output() onConfirm = new EventEmitter();
	@Output() onDecline = new EventEmitter();

	constructor(private confirmService: ConfirmationService) {}

	private showConfirm() {
		this.confirmService.confirm({
			key: this.key,
			header: this.detail,
			message: this.summary,
			accept: () => this.confirm(),
			reject: () => this.decline(),
		});
	}

	public confirm() {
		this.onConfirm.emit();
		this.confirmService.close();
		this.visibleChange.emit(false);
	}

	public decline() {
		this.onDecline.emit();
		this.confirmService.close();
		this.visibleChange.emit(false);
	}

	ngOnDestroy(): void {
		this.confirmService.close();
	}
}
