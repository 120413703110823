import { Injectable } from '@angular/core';
import { Parser } from '@core/abstractions';
import { CsvParserService, XlsxParserService } from '@core/services';

@Injectable({
	providedIn: 'root',
})
export class ParserFactoryService {
	constructor() {}

	public getParserByFileType(fileType: string): Parser {
		switch (fileType) {
			case FileType.csv:
				return new CsvParserService();
			case FileType.xlsx:
				return new XlsxParserService();
			default:
				throw new Error('Unsupported file type');
		}
	}
}

enum FileType {
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	csv = 'text/csv',
}
