import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { SignalRHelper } from '@core/utils/signalr.helper';

@Injectable({
	providedIn: 'root',
})
export class PortalStationNotificationService {
	private hubConnection: signalR.HubConnection;

	private readonly linkedEventName = 'Linked';
	private readonly addedEventName = 'Added';
	private readonly removedEventName = 'Removed';
	private readonly displayNameChangedName = 'DisplayNameChanged';

	constructor(private oidcSecurityService: OidcSecurityService) {
		const options: signalR.IHttpConnectionOptions = {
			accessTokenFactory: () => firstValueFrom(this.oidcSecurityService.getAccessToken()),
		};

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(environment.portalBaseAddress + '/signal/v1/portal/station', options)
			.withAutomaticReconnect(SignalRHelper.getReconnectPolicy())
			.configureLogging(environment.sirnalRLogLevel)
			.build();
	}


	public startConnection = () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err));
	};

	public addStationLinkedListener = (callback: (clientId: string) => void) => {
		this.hubConnection.on(this.linkedEventName, clientId => {
			callback(clientId);
		});
	};

	public addStationAddedListener = (callback: (clientId: string) => void) => {
		this.hubConnection.on(this.addedEventName, clientId => {
			callback(clientId);
		});
	};

	public addDisplayNameChangedListener = (callback: (clientId: string, displayName: string) => void) => {
		this.hubConnection.on(this.displayNameChangedName, (clientId, displayName) => {
			callback(clientId, displayName);
		});
	};

	public addStationRemovedListener = (callback: (clientId: string) => void) => {
		this.hubConnection.on(this.removedEventName, clientId => {
			callback(clientId);
		});
	};

	public removeLinkedListener = () => {
		this.hubConnection.off(this.linkedEventName);
	};

	public removeAddedListener = () => {
		this.hubConnection.off(this.addedEventName);
	};

	public removeRemovedListener = () => {
		this.hubConnection.off(this.removedEventName);
	};

	public removeDisplayNameChangedListener = () => {
		this.hubConnection.off(this.displayNameChangedName);
	};
}
