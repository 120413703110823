import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root',
})
export class AppMessageService {
	constructor(private messageService: MessageService) {}

	public success(message: string, sticky: boolean = false, summary = 'Success', life = 3000) {
		this.addMessage('success', summary, message, undefined, sticky, life);
	}

	public warn(message: string, sticky: boolean = false, summary = 'Error', life = 3000) {
		this.addMessage('warn', summary, message, undefined, sticky, life);
	}

	public error(message: string, sticky: boolean = false, summary = 'Error', life = 3000) {
		this.addMessage('error', summary, message, undefined, sticky, life);
	}

	public errorByKey(message: string, key: string, sticky: boolean = false, summary = 'Error', life = 3000) {
		this.addMessage('error', summary, message, key, sticky, life);
	}

	public clear() {
		this.messageService.clear();
	}

	private addMessage(
		severity: string,
		summary: string,
		message: string,
		key: string | undefined,
		sticky: boolean,
		life: number | undefined,
	) {
		this.messageService.add({
			key: key,
			severity: severity,
			summary: summary,
			detail: message,
			sticky: sticky,
			life: life,
		});
	}
}
