<tr class="column-width">
	@for (header of headers; track header) {
		@if (header.isVisible == true) {
			@if (header.sortable) {
				<th class="clm-{{ header.columnKey }} {{ header.styleClass }}" [pSortableColumn]="header.sortFieldName">
					<i *ngIf="header.icon" class="{{ header.icon }}"></i>
					@if (header.tooltip) {
						<span
							class="tooltip-marker"
							[pTooltip]="header.tooltip"
							[tooltipPosition]="header.tooltipPosition || 'top'"
						>
							{{ header.displayName }}
						</span>
					} @else {
						{{ header.displayName }}
					}
					<p-sortIcon [field]="header.sortFieldName" />
				</th>
			} @else {
				<th class="clm-{{ header.columnKey }} {{ header.styleClass }}">
					<i *ngIf="header.icon" class="{{ header.icon }}"></i>
					@if (header.tooltip) {
						<span
							class="tooltip-marker"
							[pTooltip]="header.tooltip"
							[tooltipPosition]="header.tooltipPosition || 'top'"
						>
							{{ header.displayName }}
						</span>
					} @else {
						{{ header.displayName }}
					}
				</th>
			}
		}
	}
</tr>
